export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (_e) {
    return true;
  }
};

export const windowChangeURL = (url: string) => {
  if (isInIframe() && window.top) {
    window.top.location.href = url;
  } else {
    window.location.href = url;
  }
};
