import type { App, Component } from 'vue';
import { createApp, h } from 'vue';
import './styles/index.css';
import './styles/placeholder.scss';
import './styles/common.scss';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'v-calendar/style.css';

import AppInit from './App.vue';
import setupRouter from './routes';
import Notifications from 'notiwind';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import websocketPlugin from '@gem/websocket';
import { createPinia } from 'pinia';
import { setupCalendar, DatePicker } from 'v-calendar';
import { initUIKit } from '@gem/uikit';
import type { VueQueryPluginOptions } from 'vue-query';
import { VueQueryPlugin } from 'vue-query';
import * as Sentry from '@sentry/vue';
import VueClickAway from 'vue3-click-away';
import { createGtm } from '@gtm-support/vue-gtm';
import { getConfig } from './config';
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import { WEB_SOCKET_KEY, WEB_SOCKET_KEY_V5 } from '@gem/websocket/const';
import { sentryCaptureException } from './modules/editor/modules/common/use-cases/sentry';
import { trackInitTimeStart } from './core/performance';
import { useI18nSetup } from '@gem/i18n';
// PWA
// import { useRegisterSW } from 'virtual:pwa-register/vue';

const { createI18nBase } = useI18nSetup();

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: 3,
        onError: (err: any) => {
          const errors = err?.response?.errors;
          sentryCaptureException('Query: API fail', JSON.stringify(err), { errors }, { level: 'log' });
        },
      },
      mutations: {
        onError(error, variables, context) {
          if (JSON.stringify(error).includes('duplicated cid')) return;

          sentryCaptureException(
            'Mutation: API fail',
            JSON.stringify(error),
            { error, variables, context },
            { level: 'log' },
          );
        },
      },
    },
  },
};

const vueApp = createApp({
  render: () => h(AppInit),
});

const i18n = createI18nBase({ locale: 'en' });

vueApp
  .use(createPinia())
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(websocketPlugin, { reconnectEnabled: true, keys: [WEB_SOCKET_KEY_V5, WEB_SOCKET_KEY] })
  .use(PerfectScrollbar)
  .use(Notifications)
  .use(VueClickAway)
  .use(setupCalendar, {})
  .use(initUIKit)
  .use(Vue3Lottie, { name: 'Vue3Lottie' })
  .use(i18n);

const routes = setupRouter(i18n);

vueApp.use(routes).use(
  createGtm({
    id: getConfig('thirdParty').gtm, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    enabled: getConfig('env') == 'production' || getConfig('configEnv') == 'development', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: routes, // Pass the router instance to automatically sync with router (optional)
  }),
);

const SentryInit = (app: App<Element>) => {
  Sentry.init({
    app,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release,
    dsn: getConfig('featureConfig').sentryCDN,
    integrations: [
      new Sentry.BrowserTracing({
        startTransactionOnLocationChange: true,
        // shouldCreateSpanForRequest: true,
        traceFetch: true,
        traceXHR: true,
        routingInstrumentation: Sentry.vueRouterInstrumentation(routes),
        shouldCreateSpanForRequest: () => {
          return true;
        },
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: getConfig('configEnv') === 'production' ? 0.2 : 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: getConfig('configEnv'),
    enabled: getConfig('env') === 'production',
  });
};
SentryInit(vueApp);

/**
 * Import base component
 */
vueApp.component('DatePicker', DatePicker);
const components = import.meta.glob<true, string, { default: Component }>('./components/atoms/*.vue', {
  eager: true,
});

Object.entries(components).forEach(([filePath, definition]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = filePath
    .split('/')
    .pop()
    ?.replace(/\.\w+$/, '');

  if (componentName) {
    // Register component on this Vue instance
    vueApp.component(componentName, definition.default);
  }
});

// Register to dom

// const intervalMS = 60 * 60 * 1000;
// useRegisterSW({
//   onRegistered(r) {
//     r &&
//       setInterval(() => {
//         r.update();
//       }, intervalMS);
//   },
// });

vueApp.config.performance = import.meta.env.PROD;
routes.isReady().then(() => {
  trackInitTimeStart();
  vueApp.mount('#app');
});
