import type { Section } from '../../common/utils/types';

import { useContentHashStore } from '../stores/content-hash';
import { hashSection, isImageComponent, isUpdatableSection, isValidSectionData } from '../utils';
import useSectionStore from '../../common/stores/section';
import useHistoryStore from '../../history/stores/history';
import useEditorStore from '../../common/stores/editor';
import { IGNORE_CID } from '../constants';
import useChangeTracker from '@/modules/interactions/hooks/useChangeTracker';
import { getHashCode } from '@/utils/hash';

const usePageChangeDetection = () => {
  const sectionStore = useSectionStore();
  const contentHashStore = useContentHashStore();
  const historyStore = useHistoryStore();
  const editorStore = useEditorStore();
  const { isInteractionHasChanged } = useChangeTracker();

  const listSectionSnapshotMap = computed(() => sectionStore.getListSectionSnapshotMap);
  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);
  const originalPageSectionHash = computed(() => contentHashStore.originalPageSectionHash);
  const hasDeleteSection = computed(() => sectionStore.getDeletedIds.length !== 0);
  const hasCIDChange = computed(() => historyStore.getCids.length !== 0);
  const sectionPosition = computed(() => sectionStore.getSectionPosition);
  const sectionItems = computed(() => sectionStore.getItems);
  const hasThemeSectionChange = computed(
    () => sectionStore.getLinkThemeSectionIds.length !== 0 || sectionStore.getUnlinkThemeSectionIds.length !== 0,
  );
  const hasChange = () => {
    return isEditThemeSection.value
      ? isThemeSectionChanged(sectionItems.value)
      : sectionItems.value.length !== sectionPosition.value.length ||
          isPageSectionPositionChanged(sectionItems.value.map((section) => section.id)) ||
          hasSectionContentChanged(sectionItems.value);
  };

  const isPageHasChanged = () => {
    return (
      hasDeleteSection.value ||
      hasCIDChange.value ||
      hasThemeSectionChange.value ||
      hasChange() ||
      isInteractionHasChanged()
    );
  };

  const getSectionsChanged = (currentSectionItems: Section[]) => {
    const newThemeSections: Section[] = [];
    const newSections: Section[] = [];
    const updatedSections: Section[] = [];
    const updatedShopifySections: Section[] = [];
    const unChangedSections: Section[] = [];

    currentSectionItems.forEach((section) => {
      const originalIdHash = originalPageSectionHash.value.get(section.id);
      if (!originalIdHash) {
        if (section.isThemeSection) {
          newThemeSections.push(section);
          return;
        }

        if (section.isShopifySection) {
          updatedShopifySections.push(section);
          return;
        }
      }

      const shopifyHash = hashSection(section);
      if (originalIdHash !== shopifyHash && section.isShopifySection) {
        updatedShopifySections.push(section);
        return;
      }

      if (!section.cid) return;
      const sectionSnapshot = listSectionSnapshotMap.value.get(section.cid);
      if (!section.id && sectionSnapshot && isValidSectionData(section)) {
        updatedSections.push({ ...section, id: sectionSnapshot.id });
        return;
      }

      const originalHash = originalPageSectionHash.value.get(section.cid);
      if (
        !section.id &&
        !originalHash &&
        !isImageComponent(section) &&
        !section.isShopifySection &&
        !sectionSnapshot &&
        isValidSectionData(section)
      ) {
        newSections.push(section);
        return;
      }

      const currentHash = hashSection(section);
      if (originalHash !== currentHash && isUpdatableSection(section)) {
        updatedSections.push(section);
        return;
      }

      unChangedSections.push(section);
    });

    return {
      newThemeSections,
      unChangedSections,
      newSections,
      updatedSections,
      updatedShopifySections,
    };
  };

  const isThemeSectionChanged = (currentSectionItems: Section[]) => {
    let isChanged = false;
    for (const section of currentSectionItems) {
      if (!section.id) continue;

      const originalHash = originalPageSectionHash.value.get(section.id);
      if (!originalHash) continue;

      const currentHash = hashSection(section, IGNORE_CID);
      if (originalHash !== currentHash) {
        isChanged = true;
        break;
      }
    }

    return isChanged;
  };

  const isPageSectionPositionChanged = (sectionPosition: string[]) => {
    return contentHashStore.originalSectionPositionHash !== getHashCode(sectionPosition);
  };

  const hasSectionContentChanged = (sections: Section[]) => {
    const { newThemeSections, updatedShopifySections, newSections, updatedSections } = getSectionsChanged(sections);

    return (
      !!newThemeSections.length || !!updatedShopifySections.length || !!newSections.length || !!updatedSections.length
    );
  };

  return {
    isPageHasChanged,
    getSectionsChanged,
    isThemeSectionChanged,
    isPageSectionPositionChanged,
  };
};

export default usePageChangeDetection;
