import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type GlobalSettingsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.GlobalSettingWhereInput>;
}>;

export type GlobalSettingsQueryResponse = {
  globalSettings?: Types.Maybe<
    Pick<Types.GlobalSettingConnection, 'totalCount'> & {
      edges?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.GlobalSettingEdge, 'cursor'> & {
              node?: Types.Maybe<Pick<Types.GlobalSetting, 'createdAt' | 'id' | 'key' | 'updatedAt' | 'value'>>;
            }
          >
        >
      >;
      pageInfo: Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>;
    }
  >;
};

export const GlobalSettingsDocument = `
    query globalSettings($after: Cursor, $before: Cursor, $first: Int, $last: Int, $where: GlobalSettingWhereInput) {
  globalSettings(
    after: $after
    before: $before
    first: $first
    last: $last
    where: $where
  ) {
    edges {
      cursor
      node {
        createdAt
        id
        key
        updatedAt
        value
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;
export const useGlobalSettingsQuery = <TData = GlobalSettingsQueryResponse, TError = unknown>(
  variables?:
    | GlobalSettingsQueryVariables
    | ComputedRef<GlobalSettingsQueryVariables>
    | Ref<GlobalSettingsQueryVariables>,
  options?: UseQueryOptions<GlobalSettingsQueryResponse, TError, TData>,
) =>
  useQuery<GlobalSettingsQueryResponse, TError, TData>(
    variables === undefined ? ['globalSettings'] : ['globalSettings', variables],
    appFetcher<GlobalSettingsQueryResponse, GlobalSettingsQueryVariables>(GlobalSettingsDocument, variables),
    options,
  );

useGlobalSettingsQuery.getKey = (
  variables?:
    | GlobalSettingsQueryVariables
    | ComputedRef<GlobalSettingsQueryVariables>
    | Ref<GlobalSettingsQueryVariables>,
) => (variables === undefined ? ['globalSettings'] : ['globalSettings', variables]);
