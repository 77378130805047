import { computed } from 'vue';
import useEditorStore from '../stores/editor';
import { useMobileOnly } from './useMobileOnly';
import type { ScreenType } from '@gem/common';

const useCurrentDevice = (): ComputedRef<ScreenType> => {
  const editorStore = useEditorStore();
  const isMobileOnly = useMobileOnly();
  return computed(() => (isMobileOnly.value ? 'mobile' : editorStore.getScreenActiveId));
};

export default useCurrentDevice;
