import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { FileSelectFragmentDoc } from '../fragments/file.generated';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type FileUploadByUrlMutationVariables = Types.Exact<{
  url: Types.Scalars['String'];
  plan: Types.ShopifyPlan;
  background?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type FileUploadByUrlMutationResponse = {
  fileUploadByURL: Pick<
    Types.File,
    | 'backupFileKey'
    | 'backupFilePath'
    | 'createdAt'
    | 'deletedAt'
    | 'fileKey'
    | 'fileName'
    | 'filePath'
    | 'height'
    | 'id'
    | 'mimeType'
    | 'size'
    | 'updatedAt'
    | 'width'
  >;
};

export const FileUploadByUrlDocument = `
    mutation FileUploadByURL($url: String!, $plan: ShopifyPlan!, $background: Boolean) {
  fileUploadByURL(url: $url, plan: $plan, background: $background) {
    ...FileSelect
  }
}
    ${FileSelectFragmentDoc}`;
export const useFileUploadByUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<FileUploadByUrlMutationResponse, TError, FileUploadByUrlMutationVariables, TContext>,
) =>
  useMutation<FileUploadByUrlMutationResponse, TError, FileUploadByUrlMutationVariables, TContext>(
    ['FileUploadByURL'],
    (
      variables:
        | FileUploadByUrlMutationVariables
        | ComputedRef<FileUploadByUrlMutationVariables>
        | Ref<FileUploadByUrlMutationVariables>,
    ) =>
      appFetcher<FileUploadByUrlMutationResponse, FileUploadByUrlMutationVariables>(
        FileUploadByUrlDocument,
        variables,
      )(),
    options,
  );
useFileUploadByUrlMutation.getKey = () => ['FileUploadByURL'];
