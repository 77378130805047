import type * as Types from '@/types/graphql';

export type ThemeSectionEdgeSelectFragment = Pick<Types.ThemeSectionEdge, 'cursor'> & {
  node?: Types.Maybe<
    Pick<
      Types.ThemeSection,
      'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'component' | 'status'
    >
  >;
};

export type ThemeSectionSelectFragment = Pick<
  Types.ThemeSection,
  'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'component' | 'status'
>;

export type ThemeSectionByIdSelectFragment = Pick<
  Types.ThemeSection,
  'component' | 'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'status'
>;

export type ThemeSectionHasMetaEdgeSelectFragment = Pick<Types.ThemeSectionEdge, 'cursor'> & {
  node?: Types.Maybe<
    Pick<
      Types.ThemeSection,
      'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'component' | 'status'
    > & { metafields?: Types.Maybe<Array<Types.Maybe<Pick<Types.ThemeSectionMeta, 'id' | 'key' | 'value'>>>> }
  >;
};

export type ThemeSectionHasMetaSelectFragment = Pick<
  Types.ThemeSection,
  'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'component' | 'status'
> & { metafields?: Types.Maybe<Array<Types.Maybe<Pick<Types.ThemeSectionMeta, 'id' | 'key' | 'value'>>>> };

export type ThemeSectionMetaEdgeFragment = Pick<Types.ThemeSectionMetaEdge, 'cursor'> & {
  node?: Types.Maybe<Pick<Types.ThemeSectionMeta, 'id' | 'key' | 'value'>>;
};

export type ThemeSectionMetaSelectFragment = Pick<Types.ThemeSectionMeta, 'id' | 'key' | 'value'>;

export const ThemeSectionSelectFragmentDoc = `
    fragment ThemeSectionSelect on ThemeSection {
  id
  name
  display
  isMobile
  createdAt
  updatedAt
  publishedAt
  component
  status
}
    `;
export const ThemeSectionEdgeSelectFragmentDoc = `
    fragment ThemeSectionEdgeSelect on ThemeSectionEdge {
  cursor
  node {
    ...ThemeSectionSelect
  }
}
    `;
export const ThemeSectionByIdSelectFragmentDoc = `
    fragment ThemeSectionByIdSelect on ThemeSection {
  component
  ...ThemeSectionSelect
}
    `;
export const ThemeSectionMetaSelectFragmentDoc = `
    fragment ThemeSectionMetaSelect on ThemeSectionMeta {
  id
  key
  value
}
    `;
export const ThemeSectionHasMetaSelectFragmentDoc = `
    fragment ThemeSectionHasMetaSelect on ThemeSection {
  ...ThemeSectionSelect
  metafields {
    ...ThemeSectionMetaSelect
  }
}
    `;
export const ThemeSectionHasMetaEdgeSelectFragmentDoc = `
    fragment ThemeSectionHasMetaEdgeSelect on ThemeSectionEdge {
  cursor
  node {
    ...ThemeSectionHasMetaSelect
  }
}
    `;
export const ThemeSectionMetaEdgeFragmentDoc = `
    fragment ThemeSectionMetaEdge on ThemeSectionMetaEdge {
  cursor
  node {
    ...ThemeSectionMetaSelect
  }
}
    `;
