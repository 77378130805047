/*
 * arrayUnique: function
 * desc:
 * input: (array)
 * output: (array)
 */
export const arrayUnique = <T = unknown>(array: Array<T>, field?: string) => {
  const uniqueId: Record<any, boolean> = {};
  try {
    if (field) {
      return array.filter((item: any) => !uniqueId[item[field]] && (uniqueId[item[field]] = true));
    } else {
      return array.filter((item: any) => !uniqueId[item] && (uniqueId[item] = true));
    }
  } catch (_e) {
    return array;
  }
};
