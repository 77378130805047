import { useMode } from '@/composables/useMode';
import { useSaleFunnel } from '@/modules/editor/modules/sale-funnels/hooks/useSaleFunnel';
import { getHashCode } from '@/utils/hash';
import useInteractionStore from '../stores';

const useChangeTracker = () => {
  const { isThemeSectionEditor } = useMode();
  const { isPostPurchasePage } = useSaleFunnel();
  const interactionStore = useInteractionStore();

  const hashData = computed(() => interactionStore.getHashData);
  const pageInteractions = computed(() => interactionStore.getListInteraction);

  const isInteractionHasChanged = () => {
    if (isThemeSectionEditor.value || isPostPurchasePage.value) {
      return false;
    }

    const newHash = getHashCode(toRaw(pageInteractions.value));
    return hashData.value !== newHash;
  };

  return {
    isInteractionHasChanged,
  };
};

export default useChangeTracker;
