import type {
  StateKey,
  State,
  PickedProductType,
  ResetOptions,
  SetPageIdOptions,
  Product,
  Collection,
  DynamicCollectionType,
  StaticCollectionType,
  ApplyResourceFilter,
} from '../types';
import { defineStore } from 'pinia';

const uniqueArrayOfObjects = <T extends { id: string }>(array: T[]): T[] => {
  const uniqueIds = new Set();
  return array.filter((item) => {
    const isNotDuplicate = !uniqueIds.has(item.id);
    uniqueIds.add(item.id);
    return isNotDuplicate;
  });
};

export const useAssignStore = defineStore('assignment', {
  state: (): State => ({
    product: {
      isOpenModal: false,
      isOpenPreviewModal: false,
      isOpenPopupWhenPublish: false,
      isOpenConfirmApplyAllResources: false,
      isOpenConfirmApplySelectedResources: false,
      isFirstTimeOpenPreview: true,
      isReplaceCurrentPage: false,
      willMakeDefaultWhenPublish: false,
      editingPage: {
        id: '',
        previousId: '',
        isOpenNewPage: false,
        isDefault: false,
        isPublished: false,
      },
      list: [],
      assignedList: [],
      assignedPreviewList: [],
      query: '',
      fetched: false,
      fetchedAssigned: false,
      assignment: {
        assigned: new Set(),
        selected: new Set(),
        pickedId: '',
      },
      pickedDynamicProduct: null,
      pickedStaticProduct: null,
      assignedPageById: {},
      applyResourceFilter: 'selected',
      syncBeforeSearchMode: false,
      searchKeyword: '',
      afterCursor: '',
    },
    collection: {
      isOpenModal: false,
      isOpenPreviewModal: false,
      isOpenPopupWhenPublish: false,
      isOpenConfirmApplyAllResources: false,
      isOpenConfirmApplySelectedResources: false,
      isFirstTimeOpenPreview: true,
      isReplaceCurrentPage: false,
      willMakeDefaultWhenPublish: false,
      editingPage: {
        id: '',
        previousId: '',
        isOpenNewPage: false,
        isDefault: false,
        isPublished: false,
      },
      list: [],
      assignedList: [],
      assignedPreviewList: [],
      query: '',
      fetched: false,
      fetchedAssigned: false,
      assignment: {
        assigned: new Set(),
        selected: new Set(),
        pickedId: '',
      },
      pickedDynamicCollection: {},
      pickedStaticCollection: {},
      assignedPageById: {},
      applyResourceFilter: 'selected',
      syncBeforeSearchMode: false,
      searchKeyword: '',
      afterCursor: '',
    },
  }),
  getters: {
    getIsOpenPopup(state) {
      return (key: StateKey) => state[key].isOpenModal;
    },
    getIsOpenPreviewPopup(state) {
      return (key: StateKey) => state[key].isOpenPreviewModal;
    },
    getIsOpenPopupWhenPublish(state) {
      return (key: StateKey) => state[key].isOpenPopupWhenPublish;
    },
    getIsOpenConfirmApplySelectedResources(state) {
      return (key: StateKey) => state[key].isOpenConfirmApplySelectedResources;
    },
    getIsOpenConfirmApplyAllResources(state) {
      return (key: StateKey) => state[key].isOpenConfirmApplyAllResources;
    },
    getIsFirstTimeOpenPreview(state) {
      return (key: StateKey) => state[key].isFirstTimeOpenPreview;
    },
    getIsReplaceCurrentPage(state) {
      return (key: StateKey) => state[key].isReplaceCurrentPage;
    },
    getEditingPageId(state) {
      return (key: StateKey) => state[key].editingPage.id;
    },
    getIsDefault(state) {
      return (key: StateKey) => state[key].editingPage.isDefault;
    },
    getIsPublished(state) {
      return (key: StateKey) => state[key].editingPage.isPublished;
    },
    getIsOpenInNewPage(state) {
      return (key: StateKey) => state[key].editingPage.isOpenNewPage;
    },
    getWillMakeDefaultWhenPublish(state) {
      return (key: StateKey) => state[key].willMakeDefaultWhenPublish;
    },
    getSelectedIds(state) {
      return (key: StateKey) => state[key].assignment.selected;
    },
    getAssignedIds(state) {
      return (key: StateKey) => state[key].assignment.assigned;
    },
    getPickedId(state) {
      return (key: StateKey) => state[key].assignment.pickedId;
    },
    getList(state) {
      return (key: StateKey) => state[key].list;
    },
    getAssignedList(state) {
      return (key: StateKey) => state[key].assignedList;
    },
    getAssignedPreviewList(state) {
      return (key: StateKey) => state[key].assignedPreviewList;
    },
    getQuery(state) {
      return (key: StateKey) => state[key].query;
    },
    getFetchAssigned(state) {
      return (key: StateKey) => state[key].fetchedAssigned;
    },
    getAssignedPageById(state) {
      return (id: string, key: StateKey) => state[key].assignedPageById[id];
    },
    getPickedDynamicProduct(state) {
      return state.product.pickedDynamicProduct;
    },
    getPickedStaticProduct(state) {
      return state.product.pickedStaticProduct;
    },
    getPickedDynamicCollection(state) {
      return state.collection.pickedDynamicCollection;
    },
    getPickedStaticCollection(state) {
      return state.collection.pickedStaticCollection;
    },
    getApplyResourceFilter(state) {
      return (key: StateKey) => state[key].applyResourceFilter;
    },
    getProductSearchKeyword(state) {
      return (key: StateKey) => state[key].searchKeyword;
    },
    getProductSyncBeforeSearchMode(state) {
      return state.product.syncBeforeSearchMode;
    },
    getProductAfterCursor(state) {
      return state.product.afterCursor;
    },
  },
  actions: {
    reset(key: StateKey, options: ResetOptions = {}) {
      const { preservedAssigned = false, preservedDefaultStatus = false, removeNewSelected = false } = options;

      this.resetCurrentList(key);
      this.setQuery('', key);
      this[key].fetchedAssigned = false;
      this[key].assignment.pickedId = '';
      // this[key].assignedPageById = {};
      // this[key].isFirstTimeOpenPreview = true;

      if (!preservedDefaultStatus) {
        this[key].editingPage.isDefault = false;
      }

      if (preservedAssigned) {
        const currentSelectedIds = this[key].assignment.selected;
        this[key].assignment.assigned = new Set(currentSelectedIds);
        return;
      }

      if (removeNewSelected) {
        const currentAssignedIds = this[key].assignment.assigned;
        this[key].assignment.selected = new Set(currentAssignedIds);
        return;
      }

      this.resetSelectedIds(key);
    },
    resetPreviewAssigned(key: StateKey) {
      this[key].isFirstTimeOpenPreview = true;
      this[key].assignedList = [];
    },
    resetSelectedIds(key: StateKey) {
      this[key].assignment.assigned = new Set();
      this[key].assignment.selected = new Set();
    },
    removeNewSelected(key: StateKey) {
      const currentAssignedIds = this[key].assignment.assigned;
      this[key].assignment.selected = new Set(currentAssignedIds);
    },
    resetCurrentList(key: StateKey) {
      this[key].list = [];
    },
    setOpenPopup(val: boolean, key: StateKey) {
      this[key].isOpenModal = val;
    },
    setOpenPopupWhenPublish(val: boolean, key: StateKey) {
      this[key].isOpenPopupWhenPublish = val;
    },
    setOpenConfirmApplyAllResources(val: boolean, key: StateKey) {
      this[key].isOpenConfirmApplyAllResources = val;
    },
    setOpenConfirmApplySelectedResources(val: boolean, key: StateKey) {
      this[key].isOpenConfirmApplySelectedResources = val;
    },
    setReplaceCurrentPage(val: boolean, key: StateKey) {
      this[key].isReplaceCurrentPage = val;
    },
    setWillMakeDefaultWhenPublish(val: boolean, key: StateKey) {
      this[key].willMakeDefaultWhenPublish = val;
    },
    setOpenPreviewPopup(val: boolean, key: StateKey) {
      if (!val && this[key].query != '') {
        this[key].query = '';
        this.resetPreviewAssigned(key);
      }
      this[key].isOpenPreviewModal = val;
    },
    setFirstTimeOpenPreview(val: boolean, key: StateKey) {
      this[key].isFirstTimeOpenPreview = val;
    },
    setEditingPageDefault(val: boolean, key: StateKey) {
      this[key].editingPage.isDefault = val;
    },
    setEditingPagePublished(val: boolean, key: StateKey) {
      this[key].editingPage.isPublished = val;
    },
    setFetchAssigned(val: boolean, key: StateKey) {
      this[key].fetchedAssigned = val;
    },
    setQuery(val: string, key: StateKey) {
      this[key].query = val;
    },
    setApplyResourceFilter(val: ApplyResourceFilter, key: StateKey) {
      this[key].applyResourceFilter = val;
    },
    setEditingPageId(val: string, key: StateKey, options?: SetPageIdOptions) {
      this[key].editingPage.id = val;
      if (options?.checkIsNewPage) this.checkEditingNewPage(val, key);
      this.setEditingPageDefault(options?.isDefault ?? false, key);
      this.setEditingPagePublished(options?.isPublished ?? false, key);
    },
    checkEditingNewPage(currentPageId: string, stateKey: StateKey) {
      const editingPage = this[stateKey].editingPage;
      if (currentPageId !== editingPage.previousId) {
        editingPage.isOpenNewPage = true;
        this.reset(stateKey);
      }
      editingPage.previousId = currentPageId;
    },
    initAssignedIds(assignedIds: string[], key: StateKey) {
      const selectedId = this[key].assignment.selected;
      this[key].assignment.assigned = new Set(assignedIds);
      this[key].assignment.selected = new Set([...assignedIds, ...selectedId]);
      if (assignedIds?.length) {
        this[key].assignment.pickedId = assignedIds[0];
      }
    },
    setAssignedPageById(args: { id: string; pageId: string }, key: StateKey) {
      const { id, pageId } = args;
      const assignedPageById = this[key].assignedPageById;
      const storedPageIds = assignedPageById[id];

      if (!storedPageIds) {
        const newPageIds = new Set<string>();
        newPageIds.add(pageId);
        assignedPageById[id] = newPageIds;
        return;
      }

      assignedPageById[id] = storedPageIds.add(pageId);
    },
    handleSelect(id: string, key: StateKey) {
      const { selected } = this[key].assignment;
      selected.has(id) ? selected.delete(id) : selected.add(id);
    },
    setPickedDynamicProduct(pickedDynamicProduct: PickedProductType) {
      this.product.pickedDynamicProduct = pickedDynamicProduct;
    },
    setPickedStaticProduct(pickedStaticProduct: PickedProductType) {
      this.product.pickedStaticProduct = pickedStaticProduct;
    },
    setListProduct(newItems: Product[]) {
      this.product.list = uniqueArrayOfObjects<Product>(this.product.list.concat(newItems));
    },
    setListCollection(newItems: Collection[]) {
      this.collection.list = uniqueArrayOfObjects<Collection>(this.collection.list.concat(newItems));
    },
    setListAssignedProduct(newItems: Product[]) {
      this.product.assignedList = uniqueArrayOfObjects<Product>(this.product.assignedList.concat(newItems));
    },
    setListAssignedPreviewProduct(newItems: Product[]) {
      this.product.assignedPreviewList = uniqueArrayOfObjects<Product>([...newItems]);
    },
    updateListAssignedProduct(product?: Product) {
      if (product?.id === this.product.pickedDynamicProduct?.productId) {
        this.product.pickedDynamicProduct = {
          ...this.product.pickedDynamicProduct,
          productHandle: product?.handle,
          productTitle: product?.title,
          productImage: product?.image,
          productState: product?.status,
        };
      }
      if (product?.id === this.product.pickedStaticProduct?.productId) {
        this.product.pickedStaticProduct = {
          ...this.product.pickedStaticProduct,
          productHandle: product?.handle,
          productTitle: product?.title,
          productImage: product?.image,
          productState: product?.status,
        };
      }
      this.product.assignedList = this.product.assignedList.map((v) => {
        if (v.id === product?.id) {
          return {
            ...v,
            handle: product?.handle,
            image: product?.image,
            status: product?.status,
            title: product?.title,
          };
        }
        return v;
      });
    },
    setListAssignedCollection(newItems: Collection[]) {
      this.collection.assignedList = uniqueArrayOfObjects<Collection>(this.collection.assignedList.concat(newItems));
    },
    setListAssignedPreviewCollection(newItems: Collection[]) {
      this.collection.assignedPreviewList = uniqueArrayOfObjects<Collection>([...newItems]);
    },
    updateListAssignedCollection(item?: Collection) {
      if (item?.id === this.collection.pickedDynamicCollection?.collectionId) {
        this.collection.pickedDynamicCollection = {
          ...this.collection.pickedDynamicCollection,
          collectionHandle: item?.handle,
          collectionTitle: item?.title,
          collectionImage: item?.image,
          collectionProductCount: item?.productCount,
        };
      }

      this.collection.assignedList = this.collection.assignedList.map((v) => {
        if (v.id === item?.id) {
          return {
            ...v,
            handle: item?.handle,
            image: item?.image,
            title: item?.title,
            productCount: item?.productCount,
          };
        }
        return v;
      });
    },
    setPickedDynamicCollection(pickedDynamicCollection: DynamicCollectionType) {
      this.collection.pickedDynamicCollection = pickedDynamicCollection;
    },
    setPickedStaticCollection(pickedStaticCollection: StaticCollectionType) {
      this.collection.pickedStaticCollection = pickedStaticCollection;
    },
    setProductSyncBeforeSearchMode(value: boolean) {
      this.product.syncBeforeSearchMode = value;
    },
    setProductSearchKeyword(keyword: string) {
      this.product.searchKeyword = keyword;
    },
    setProductAfterCursor(cursor: string) {
      this.product.afterCursor = cursor;
    },
  },
});

export default useAssignStore;
