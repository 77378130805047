import {
  componentCreate,
  componentDelete,
  componentForceUpdateConfigurations,
} from '@/modules/editor/modules/common/use-cases/component';
import { actionInsertLibraryToThemeSection } from '@/modules/editor/modules/history/use-cases/action';
import { historyCreate } from '@/modules/editor/modules/history/use-cases/history';
import { cloneDeepObject, parseJson } from '@/utils/common';
import { RenderSectionCID } from '@/utils/id';
import { replaceAllObjKeys } from '@/utils/replaceAllObjKeys';
import useEditorStore from '../../common/stores/editor';
import useSectionStore from '../../common/stores/section';
import { restoreSections, sectionCreate } from '../../common/use-cases/section';
import { BG_DEFAULT_SETTINGS, STICKY_TAG } from '../../common/utils/const';
import { convertComponentToJSON, convertComponentToString, generateNewUid } from '../../common/utils/section/component';
import type { Component, Section } from '../../common/utils/types';
import useHistoryStore from '../../history/stores/history';
import useLibraryStore from '../stores/library';
import type { InsertFromType } from '../utils/types';
import { useScrollToSection } from './useScrollToSection';
import { useScrollToFirstSectionInTemplate } from './useScrollToFirstSectionInTemplate';

type addSectionOptions = {
  section: Section;
  shouldScrollIntoView?: boolean;
  insertFromType?: InsertFromType;
  isReplaceProduct?: boolean;
};

const insertToIndexOfArray = <T>(array: T[], index: number, item: T | T[]) => {
  return [...array.slice(0, index), ...(Array.isArray(item) ? item : [item]), ...array.slice(index)];
};

const useInsertTemplate = () => {
  const libraryStore = useLibraryStore();
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const { scrollNewSectionIntoView } = useScrollToSection();
  const { scrollFirstSectionIntoView } = useScrollToFirstSectionInTemplate();
  const linkThemeSectionIds = computed(() => sectionStore.getLinkThemeSectionIds);
  const anchor = computed(() => libraryStore.getAnchor);
  const isReplaceFontWithThemeFont = computed(() => libraryStore.getIsReplaceFontWithThemeFont);

  const getSectionCID = () => {
    const historyCIDs = useHistoryStore().getCids;
    const sectionStoreCIDs = useSectionStore().getItems.map((item) => item.cid as string);
    const expelStoreCIDs = [...historyCIDs, ...sectionStoreCIDs];
    return RenderSectionCID(expelStoreCIDs);
  };

  const isThemeSectionEditor = computed(() => editorStore.getIsEditThemeSection);
  const pageType = computed(() => editorStore.getEditingPageType);

  const addSection = ({ section, shouldScrollIntoView = true, insertFromType }: addSectionOptions) => {
    const component = convertComponentToJSON(section?.component || '');
    if (!component) return;
    if (isThemeSectionEditor.value) {
      const newSection = section;
      const component = convertComponentToJSON(newSection?.component || '');
      if (!component) return;
      addThemeSectionLibrary(component);
      return;
    }
    generateNewUid(component);
    if (!section.isThemeSection) {
      convertSectionComponentWhenInsert(component, insertFromType);
    }
    const sectionName = section.isThemeSection ? section.name : `Section ${component?.uid}`;
    const newSection: Section = {
      id: '', // create new id == 0
      cid: getSectionCID(),
      isCustom: false,
      isCustomNew: false,
      isImportFromLibrary: true,
      name: sectionName,
      display: true,
      importFromLibraryType: insertFromType,
      component: convertComponentToString(component),
    };
    if (section.isThemeSection) {
      newSection.isThemeSection = section.isThemeSection;
      newSection.id = section.id;
      newSection.status = section.status;
      sectionStore.setLinkThemeSectionId([...linkThemeSectionIds.value, section.id]);
    }
    if (section.name == STICKY_TAG) {
      const stickies = useSectionStore().stickies;
      let cId;
      if (stickies.length) {
        cId = stickies.pop()?.cid;
      }

      sectionCreate(newSection, {
        cid: cId,
        direction: cId ? 'after' : 'before',
      });
    } else {
      sectionCreate(newSection, {
        cid: anchor.value.cid,
        direction: anchor.value.direction,
      });
    }
    if (shouldScrollIntoView) {
      scrollNewSectionIntoView(component.uid);
      editorStore.setEditingComponentUid(component.uid);
    }

    return;
  };

  const addThemeSectionLibrary = (component: Component) => {
    if (!component) return;

    addThemeSectionLibraryFromBuildWithSection(component, 'after');
  };

  const initSectionFromLibrary = ({ section, insertFromType, isReplaceProduct }: addSectionOptions) => {
    const component = convertComponentToJSON(section?.component || '');
    const newSection: Section = {
      id: '',
      cid: getSectionCID(),
      isCustom: false,
      isCustomNew: false,
      isImportFromLibrary: true,
      name: `Section ${component?.uid}`,
      display: true,
      importFromLibraryType: insertFromType,
      component: '',
    };
    if (!component) return newSection;
    generateNewUid(component);
    if (!section.isThemeSection) {
      convertSectionComponentWhenInsert(component, insertFromType, isReplaceProduct);
    }
    newSection.component = convertComponentToString(component);
    newSection.name = `Section ${component?.uid}`;
    if (section.isThemeSection) {
      handleThemeSection(section, newSection);
    }

    return newSection;
  };

  const handleThemeSection = (section: Section, newSection: Section) => {
    newSection.isThemeSection = section.isThemeSection;
    newSection.id = section.id;
    newSection.status = section.status;
    sectionStore.setLinkThemeSectionId([...linkThemeSectionIds.value, section.id]);
  };

  const getNewSections = (sections: Section[], insertFromType?: InsertFromType, isReplaceProduct?: boolean) => {
    return sections.map((section) => {
      return initSectionFromLibrary({ section, insertFromType, isReplaceProduct });
    });
  };

  const addMultipleSections = ({
    sections,
    insertFromType,
    clearAllSectionData,
    onlyShopifySection,
    listOfHistory,
    isReplaceProduct,
  }: {
    sections: Section[];
    insertFromType?: InsertFromType;
    clearAllSectionData: boolean;
    onlyShopifySection?: boolean;
    listOfHistory?: Section[];
    isReplaceProduct?: boolean;
  }) => {
    if (sections?.length > 0 || onlyShopifySection) {
      const listSection = onlyShopifySection ? sections : getNewSections(sections, insertFromType, isReplaceProduct);
      const itemsInStore = sectionStore.getItems;
      if (clearAllSectionData) {
        const themeSectionIds = itemsInStore.filter((item) => item.isThemeSection).map((item) => item.cid);
        themeSectionIds.forEach((cid) => {
          sectionStore.setUnlinkThemeSectionId(cid as string);
        });
      }
      const anchorIndex = itemsInStore.findIndex((item) => item.cid === anchor.value.cid);
      const insertIndex =
        anchorIndex === -1 ? itemsInStore.length : anchor.value.direction == 'after' ? anchorIndex + 1 : anchorIndex;
      const newSections = clearAllSectionData
        ? listSection
        : insertToIndexOfArray(itemsInStore, insertIndex, listSection);
      restoreSections(newSections, {
        listOfHistory: listOfHistory ?? listSection,
      });
      libraryStore.setClearAllSectionData(false);
      libraryStore.setIsReplaceFontWithThemeFont(true);

      if (!clearAllSectionData) {
        scrollFirstSectionIntoView(listSection, (uid) => {
          editorStore.setEditingComponentUid(uid);
        });
      }
    }
  };

  const createRowWrapper = (component: Component): Component[] => {
    const cloneConfigurations = cloneDeepObject(component);

    const newRowComponent: Component = {
      uid: '',
      childrens: cloneConfigurations?.childrens,
      tag: 'Row',
      label: 'Row',
      advanced: cloneConfigurations?.advanced,
      styles: {
        background: { ...BG_DEFAULT_SETTINGS },
        ...cloneConfigurations.styles,
      },
      settings: cloneConfigurations?.settings,
    };
    generateNewUid(newRowComponent);

    return [newRowComponent];
  };

  const addThemeSectionLibraryFromBuildWithSection = (
    component: Component,
    direction: 'before' | 'after' = 'after',
  ) => {
    if (!component) return;

    generateNewUid(component);
    convertSectionComponentWhenInsert(component);
    const targetSection = sectionStore.getItems?.[0];
    targetSection.isImportFromLibrary = true;

    const targetSectionComponent: any = parseJson(targetSection?.component ?? '');
    const isSectionHasMultipleCol = targetSectionComponent?.settings?.layout?.desktop?.cols?.length !== 1;

    const child = createRowWrapper(component);
    if (!child) return;

    let scrollToUid = child[0].uid;

    const deleteData = {
      uids: targetSectionComponent.childrens.map((component: Component) => component.uid),
      newComponents: cloneDeepObject(targetSectionComponent.childrens),
      newComponent: JSON.stringify(targetSectionComponent),
      parentData: { toParent: targetSectionComponent?.uid, toIndex: 0 },
    };

    componentDelete(deleteData.uids, {
      noRecordHistory: true,
    });

    const configsData = {
      oldComponent: cloneDeepObject(targetSectionComponent),
    };

    if (isSectionHasMultipleCol) {
      const cloneConfigurations = cloneDeepObject(targetSectionComponent);
      const newRowComponent: Component = {
        uid: '',
        childrens: cloneConfigurations?.childrens,
        tag: 'Row',
        label: 'Row',
        advanced: cloneConfigurations?.advanced,
        styles: {
          ...cloneConfigurations.styles,
          background: { ...BG_DEFAULT_SETTINGS },
        },
        settings: cloneConfigurations?.settings,
      };
      generateNewUid(newRowComponent);

      const newColComponent: Component = {
        uid: '',
        childrens: direction === 'before' ? [...child, newRowComponent] : [newRowComponent, ...child],
        tag: 'Col',
        label: 'Block',
      };
      generateNewUid(newColComponent);

      targetSectionComponent.settings.layout = { desktop: { cols: [12] } };
      targetSectionComponent.childrens = [newColComponent];
      scrollToUid = child[0].uid;
    } else {
      targetSectionComponent.childrens[0].childrens =
        direction === 'before'
          ? [...child, ...targetSectionComponent.childrens[0].childrens]
          : [...targetSectionComponent.childrens[0].childrens, ...child];
    }

    componentForceUpdateConfigurations({
      cid: targetSection.cid || '',
      component: JSON.stringify(targetSectionComponent),
      settings: {
        componentUid: targetSectionComponent.uid,
      },
      options: {
        noRecordHistory: true,
      },
    });

    const createData = {
      cid: targetSection.cid || '',
      uids: targetSectionComponent?.childrens.map((i: Component) => i?.uid),
      newComponent: JSON.stringify(targetSectionComponent),
      newComponents: targetSectionComponent?.childrens,
      parentData: { toParent: targetSectionComponent?.uid, toIndex: targetSectionComponent?.childrens.length },
      options: {
        noRecordHistory: true,
      },
    };

    componentCreate({ ...createData });

    historyCreate(
      actionInsertLibraryToThemeSection({
        cid: createData.cid,
        create: { ...createData },
        delete: { ...deleteData },
        configs: { ...configsData, newComponent: cloneDeepObject(targetSectionComponent) },
      }),
    );

    scrollNewSectionIntoView(scrollToUid);
  };

  const convertSectionComponentWhenInsert = (
    jsonComponent: Component,
    insertFromType?: InsertFromType,
    isReplaceProduct = true,
  ) => {
    if (
      insertFromType === 'library_sections' ||
      (insertFromType === 'library_templates' && isReplaceFontWithThemeFont.value)
    ) {
      jsonComponent.settings && replaceFontWithThemeFont(jsonComponent.settings);
      jsonComponent.styles && replaceFontWithThemeFont(jsonComponent.styles);
    }

    if (jsonComponent?.tag == 'Product' && jsonComponent?.settings?.productSetting && isReplaceProduct) {
      jsonComponent.settings.productSetting.productId = 'latest';
      jsonComponent.settings.productSetting.productHandle = undefined;
      if (pageType.value != 'GP_PRODUCT') {
        jsonComponent.settings.productSetting.productStatus = 'static';
      }
    }
    if (jsonComponent?.tag == 'ProductList' && jsonComponent?.settings?.productSetting) {
      jsonComponent.settings.productSetting = {
        productSrc: 'PickProduct',
        productIds: [],
        collectionId: 'Latest',
      };
    }
    if (jsonComponent.childrens?.length) {
      for (const element of jsonComponent.childrens) {
        const children = element;
        convertSectionComponentWhenInsert(children, insertFromType, isReplaceProduct);
      }
    }
    return jsonComponent;
  };

  const replaceFontWithThemeFont = (settings: Record<string, any>) => {
    for (const key in settings) {
      const setting = settings[key];
      replaceDeviceFonts(setting);
      replaceStateFonts(setting);
    }
  };

  const replaceStateFonts = (setting: any) => {
    const states = ['normal', 'hover', 'active', 'focus', 'price', 'compareAtPrice'];
    for (const state of states) {
      if (!setting?.[state]) continue;
      replaceDeviceFonts(setting[state]);
    }
  };

  const replaceDeviceFonts = (setting: any) => {
    const deviceTypes = ['desktop', 'tablet', 'mobile'];
    if (!setting || !setting.custom || typeof setting.custom !== 'object') return;
    const isExistFontFamily = JSON.stringify(setting)?.match(/fontFamily/g);
    if (!isExistFontFamily) return;

    let isUsedDevice = false;
    deviceTypes.forEach((deviceType) => {
      const deviceSetting = setting.custom[deviceType];
      if (deviceSetting && deviceSetting.fontFamily) {
        isUsedDevice = true;
        setting.custom[deviceType] = replaceAllObjKeys(deviceSetting, ['fontFamily']);
      }
    });

    if (!isUsedDevice && setting.custom.fontFamily) {
      setting.custom = replaceAllObjKeys(setting.custom, ['fontFamily']);
    }
  };

  return {
    addSection,
    createRowWrapper,
    addMultipleSections,
    getSectionCID,
    addThemeSectionLibrary,
    convertSectionComponentWhenInsert,
    scrollNewSectionIntoView,
    addThemeSectionLibraryFromBuildWithSection,
  };
};

export default useInsertTemplate;
