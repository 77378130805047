import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ShopMetaSelectFragmentDoc } from '../fragments/shop-meta.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ShopMetaByKeyQueryVariables = Types.Exact<{
  key: Types.Scalars['String'];
}>;

export type ShopMetaByKeyQueryResponse = {
  shopMetaByKey?: Types.Maybe<Pick<Types.ShopMeta, 'id' | 'value' | 'key' | 'createdAt' | 'updatedAt'>>;
};

export const ShopMetaByKeyDocument = `
    query shopMetaByKey($key: String!) {
  shopMetaByKey(key: $key) {
    ...ShopMetaSelect
  }
}
    ${ShopMetaSelectFragmentDoc}`;
export const useShopMetaByKeyQuery = <TData = ShopMetaByKeyQueryResponse, TError = unknown>(
  variables: ShopMetaByKeyQueryVariables | ComputedRef<ShopMetaByKeyQueryVariables> | Ref<ShopMetaByKeyQueryVariables>,
  options?: UseQueryOptions<ShopMetaByKeyQueryResponse, TError, TData>,
) =>
  useQuery<ShopMetaByKeyQueryResponse, TError, TData>(
    ['shopMetaByKey', variables],
    appFetcher<ShopMetaByKeyQueryResponse, ShopMetaByKeyQueryVariables>(ShopMetaByKeyDocument, variables),
    options,
  );

useShopMetaByKeyQuery.getKey = (
  variables: ShopMetaByKeyQueryVariables | ComputedRef<ShopMetaByKeyQueryVariables> | Ref<ShopMetaByKeyQueryVariables>,
) => ['shopMetaByKey', variables];
