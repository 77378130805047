import type { Router, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import instantRoutes from './modules/instant/routes';
import HyperlinkLayout from './modules/hyperlink/layouts/HyperlinkLayout.vue';
import type { I18n } from '@gem/i18n';
import { useI18nSetup } from '@gem/i18n';

const { loadOnBeforeEach } = useI18nSetup();

const setupRouter = (i18n: I18n): Router => {
  const routeMap = import.meta.glob<true, string, { default: RouteRecordRaw[] }>('./modules/*/routes.ts', {
    eager: true,
  });

  const storefrontRoutes = Object.values(routeMap)
    .map((data) => data.default)
    .flat()
    .filter((item) => {
      if (
        !item.name?.toString().includes('Instant') &&
        !instantRoutes.find((route) => route.name === item.name?.toString())
      )
        return item;
    });

  const instantWelcomeRouters = Object.values(routeMap)
    .map((data) => data.default)
    .flat()
    .filter((item) => {
      if (item.name?.toString().includes('Instant') && item.name?.toString() === 'InstantWelcome') return item;
    });

  const routes: RouteRecordRaw[] = [
    {
      path: '/',
      children: [
        {
          path: '',
          redirect: {
            name: 'InstantPages',
          },
        },
        {
          path: 'instant/welcome',
          component: () => import('@/layouts/Welcome.vue'),
          children: instantWelcomeRouters,
        },
        {
          path: 'instant',
          name: 'Instant',
          component: () => import('@/layouts/Index.vue'),
          children: instantRoutes,
        },
        {
          path: 'instant/hyperlink',
          name: 'InstantHyperlink',
          component: HyperlinkLayout,
          children: [
            {
              path: 'edit',
              name: 'InstantHyperlinkEdit',
              component: () => import('@/modules/hyperlink/pages/Edit.vue'),
            },
            {
              path: 'new',
              name: 'InstantHyperlinkNew',
              component: () => import('@/modules/hyperlink/pages/New.vue'),
            },
          ],
        },
        {
          path: ':shopHandle/',
          component: () => import('@/layouts/Index.vue'),
          children: storefrontRoutes,
        },
        {
          path: ':shopHandle/:pathMatch(.*)',
          component: () => import('@/PathNotFound.vue'),
        },
      ],
    },
    {
      path: '/preview',
      component: () => import('@/modules/preview/pages/PreviewPage.vue'),
    },
    {
      path: '/icons',
      component: () => import('@/modules/preview/pages/Icons.vue'),
    },
    {
      path: '/post-purchase/preview',
      component: () => import('@/modules/preview/pages/PreviewPostPurchasePage.vue'),
    },
  ];
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  // Handle check token in localStorage
  router.beforeEach(async (_to, _from, next) => {
    const locale = localStorage.getItem('lang');
    await loadOnBeforeEach(i18n, locale as string, next);
    next();
  });

  return router;
};

export default setupRouter;
