import type { CreatePageSectionInput } from '@gem/common';
import { defineStore } from 'pinia';

type StateTypes = {
  errorCount: number;
  analyticErrorCount: number;
  duplicateCIDSectionList: Map<string, CreatePageSectionInput>;
};

export const useErrorCollectorStore = defineStore('error-collector', {
  state: (): StateTypes => ({
    errorCount: 0,
    analyticErrorCount: 0, // Track non-network error
    duplicateCIDSectionList: new Map<string, CreatePageSectionInput>(),
  }),
  actions: {
    addError(error: unknown | Error | string) {
      this.errorCount++;
      if (typeof error === 'string' && error.includes('Network request failed')) {
        this.analyticErrorCount++;
      }
      if (error instanceof Error && error.message.includes('Network request failed')) {
        this.analyticErrorCount++;
      }
      if (JSON.stringify(error).includes('Network request failed')) {
        this.analyticErrorCount++;
      }
    },
    clearError() {
      this.errorCount = 0;
      this.analyticErrorCount = 0;
    },
    hasError() {
      return this.errorCount;
    },
    hasAnalyticError() {
      return this.analyticErrorCount;
    },
    addDuplicateCIDSection(section: CreatePageSectionInput) {
      if (!section.cid || typeof section.cid !== 'string') return;
      if (!this.duplicateCIDSectionList.has(section.cid)) {
        this.duplicateCIDSectionList.set(section.cid, section);
      }
    },
    clearDuplicateCIDSection() {
      this.duplicateCIDSectionList.clear();
    },
  },
});
