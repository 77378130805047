import { defineStore } from 'pinia';
import type { Component } from '../../common/utils/types';

type State = {
  linkedComponents: Map<string, Component>;
};

export const useLinkedToSalesPageStore = defineStore('linkedToSalesPage', {
  state: (): State => ({
    linkedComponents: new Map<string, Component>(),
  }),
  getters: {
    getLinkedComponents: (state) => state.linkedComponents,
  },
  actions: {
    setLinkedComponents(value: Map<string, Component>) {
      this.linkedComponents = value;
    },
    addLinkedComponent(key: string, value: Component) {
      this.linkedComponents.set(key, value);
    },
    deleteLinkedComponent(key: string) {
      this.linkedComponents.delete(key);
    },
  },
});
